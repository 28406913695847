import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import BannerBasic from "../components/Content/Banner/Basic";
import LeadIn from "../components/Content/LeadIn/Index";
import FeatureColumn from "../components/Content/FeatureColumn/Index";

// data
import featuredBrands from "../pages/static-pages/content/where-to-buy.yaml";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Where to buy"
        description="Backed by over 110 years of innovation, Conex Bänniger is the recognised leader in press fittings across the plumbing and HVAC-R industries."
      />
      <BannerBasic title="Where To Buy" />
      <LeadIn
        brandMediaUrl="/logos/logo.svg"
        brandMediaTitle="Conex Bänninger Logo"
        brandMediaWidth="306px"
        summary="Backed by over 110 years of innovation, Conex Bänniger is the recognised leader in press fittings across the plumbing and HVAC-R industries."
      />
      <FeatureColumn grid="large" columns="3" data={featuredBrands.items} />
    </Layout>
  );
};

export default IndexPage;
